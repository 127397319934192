import axios from 'axios'
import {api, CancelToken} from '../api'
import {adaptApplication} from './adapter'

let cancelCreation

const createApplication = setApplication => ({
  chosenProposal,
  product,
  sessionToken,
}) => {
  if (cancelCreation) cancelCreation()

  return api
    .post(
      `/applications`,
      {product, simulation: chosenProposal},
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelCreation = c
        }),
        headers: {Authorization: `Bearer ${sessionToken}`},
      },
    )
    .then(response => {
      const application = adaptApplication(response.data)

      setApplication(application)
      return application
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error
      }
    })
}

export {createApplication}
