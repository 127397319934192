import {useCallback} from 'react'
import {useDispatch} from 'redux-react-hook'
import * as loanConditions from './loanConditions.actions'

const useLoanConditionsActions = () => {
  const dispatch = useDispatch()
  const fetchLimits = useCallback(
    ({sessionToken, product}) =>
      loanConditions.fetchLimits({sessionToken, product})(dispatch),
    [dispatch],
  )
  const fetchLoanSimulations = useCallback(
    ({sessionToken, loanAmount, product}) =>
      loanConditions.fetchLoanSimulations({sessionToken, loanAmount, product})(
        dispatch,
      ),
    [dispatch],
  )
  const setLoanAmount = useCallback(
    ({loanAmount}) => loanConditions.setLoanAmount({loanAmount})(dispatch),
    [dispatch],
  )
  const clearLoanSimulation = useCallback(
    () => loanConditions.clearLoanSimulation()(dispatch),
    [dispatch],
  )
  const setLoanTerm = useCallback(
    ({loanTerm}) => loanConditions.setLoanTerm({loanTerm})(dispatch),
    [dispatch],
  )
  const setProductType = useCallback(
    ({productType}) => loanConditions.setProductType({productType})(dispatch),
    [dispatch],
  )
  const setSelectedSimulation = useCallback(
    ({selectedSimulation}) =>
      loanConditions.setSelectedSimulation({selectedSimulation})(dispatch),
    [dispatch],
  )

  return {
    clearLoanSimulation,
    fetchLimits,
    fetchLoanSimulations,
    setLoanAmount,
    setLoanTerm,
    setProductType,
    setSelectedSimulation,
  }
}

export {useLoanConditionsActions}
