import {createAction} from 'redux-act'
import * as Sentry from '@sentry/browser'
import {log} from '../../helpers/log'
import {
  getCustumerLoanLimits,
  simulateLoan,
} from './../../services/api/simulation/calculator'

const actions = {
  fetchLoanSimulations: createAction('fetch the remote simulations'),
  fetchLoanSimulationsSuccess: createAction(
    'successfully fetched the remote simulations',
  ),
  fetchLoanSimulationsError: createAction(
    'error fetching the remote simulations',
  ),
  fetchLimits: createAction('fetch the simulation limits'),
  fetchLimitsSuccess: createAction(
    'successfully fetched the simulation limits',
  ),
  fetchLimitsError: createAction('error fetching the simulation limits'),
  setLoanAmount: createAction('set the loan amount value'),
  setLoanTerm: createAction('set the loan term'),
  setSelectedSimulation: createAction('set the selected simulation'),
  setProductType: createAction('set the selected product type'),
  clearLoanSimulation: createAction('clears the loan simulation data'),
}

const fetchLoanSimulations = ({sessionToken, loanAmount, product}) => {
  return dispatch => {
    log('simulationFetchAttempt')

    dispatch(actions.fetchLoanSimulations())
    dispatch(actions.clearLoanSimulation())

    return simulateLoan({sessionToken, loanAmount, product})
      .then(({availableSimulations}) => {
        log('simulationFetchSuccess')

        const availableTerms = availableSimulations.map(val => val.term)

        dispatch(
          actions.fetchLoanSimulationsSuccess({
            loanSimulations: availableSimulations,
            maxLoanTerm: Math.max(...availableTerms),
            minLoanTerm: Math.min(...availableTerms),
          }),
        )
      })
      .catch(error => {
        log('simuationFetchError')
        Sentry.configureScope(scope => {
          scope.setExtra('AT', sessionToken)
        })

        if (!(error instanceof TypeError)) {
          dispatch(actions.fetchLoanSimulationsError())
        }

        throw error
      })
  }
}

const fetchLimits = ({sessionToken, product}) => {
  return dispatch => {
    dispatch(actions.fetchLimits())
    dispatch(actions.clearLoanSimulation())

    if (sessionToken) {
      log('limitFetchAttempt')
      Sentry.configureScope(scope => {
        scope.setExtra('AT', sessionToken)
      })

      return getCustumerLoanLimits({sessionToken, product})
        .then(response => {
          log('limitFetchSuccess')

          dispatch(
            actions.fetchLimitsSuccess({
              limits: response,
            }),
          )
        })
        .catch(error => {
          log('limitFetchError')

          dispatch(actions.fetchLimitsError())

          throw error
        })
    } else {
      return null
    }
  }
}

const setLoanAmount = ({loanAmount}) => {
  return dispatch => {
    dispatch(actions.setLoanAmount({loanAmount}))
  }
}

const setLoanTerm = ({loanTerm}) => {
  return dispatch => {
    dispatch(actions.setLoanTerm({loanTerm}))
  }
}

const setProductType = ({productType}) => {
  return dispatch => {
    dispatch(actions.setProductType({productType}))
  }
}

const setSelectedSimulation = ({selectedSimulation}) => {
  return dispatch => {
    dispatch(actions.setSelectedSimulation({selectedSimulation}))
  }
}

const clearLoanSimulation = () => {
  return dispatch => {
    dispatch(actions.clearLoanSimulation())
  }
}

export {
  actions,
  clearLoanSimulation,
  fetchLimits,
  fetchLoanSimulations,
  setLoanAmount,
  setLoanTerm,
  setProductType,
  setSelectedSimulation,
}
