import React from 'react'
import CurrencyFormat from 'react-currency-format'
import InputMask from 'react-input-mask'
import {TextField} from '@creditas/input'
import PropTypes from 'prop-types'

const propTypes = {
  onChange: PropTypes.func,
}

const CurrencyTextField = props => {
  return (
    <CurrencyFormat
      {...props}
      customInput={TextField}
      prefix="R$ "
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={0}
      fixedDecimalScale={true}
    />
  )
}

const MaskedTextField = props => {
  return (
    <InputMask {...props} maskChar={null} onChange={props.onChange}>
      {inputProps => <TextField {...inputProps} />}
    </InputMask>
  )
}

MaskedTextField.propTypes = propTypes

export {CurrencyTextField, MaskedTextField}
