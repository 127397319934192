/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {
  firstPageMessage,
  fallbackToLocalTopUp,
} from './../../helpers/webviewMessages'
import {useSessionToken} from './../../hooks/useSessionToken.hook'
import {eventDisplayedSimulation} from './../../services/api/events/create'
import {useLoanConditionsState} from './../../store/loanConditions/useLoanConditionsState.hook'
import {useLoanConditionsActions} from './../../store/loanConditions/useLoanConditionsAction.hook'
import {useCustomerState} from './../../store/customer/useCustomerState.hook'

const useCalculator = ({minLoanAmount, maxLoanAmount, product}) => {
  const {t} = useTranslation(['calculator', 'common'])

  const {sessionToken} = useSessionToken()
  const {hasOpenInstallment} = useCustomerState()
  const {
    loanAmount,
    loanTerm,
    loanSimulations,
    selectedSimulation,
    fetchLimitError,
    fetchLoanSimulationsError,
  } = useLoanConditionsState()

  const {
    clearLoanSimulation,
    setSelectedSimulation,
    fetchLoanSimulations,
    fetchLimits,
  } = useLoanConditionsActions()

  useEffect(() => {
    if (fetchLimitError === true) {
      fallbackToLocalTopUp()
    }
  }, [fetchLimitError])

  useEffect(() => {
    firstPageMessage()
  }, [])

  useEffect(() => {
    if (selectedSimulation) {
      eventDisplayedSimulation({sessionToken, product, selectedSimulation})
    }
  }, [selectedSimulation])

  useEffect(() => {
    if (sessionToken) {
      fetchLimits({sessionToken, product})
    }
  }, [sessionToken])

  useEffect(() => {
    if (loanAmount >= minLoanAmount && loanAmount <= maxLoanAmount) {
      fetchLoanSimulations({sessionToken, loanAmount, product})
    }
  }, [loanAmount])

  useEffect(() => {
    if (
      loanAmount &&
      loanTerm &&
      loanSimulations &&
      loanSimulations.length > 0
    ) {
      const simulation = loanSimulations.filter(
        val => val.term === parseInt(loanTerm, 10),
      )[0]

      setSelectedSimulation({selectedSimulation: simulation})
    }
  }, [loanTerm, loanSimulations])

  useEffect(() => {
    if (loanAmount < minLoanAmount || loanAmount > maxLoanAmount) {
      clearLoanSimulation()
    }
  }, [loanAmount, loanSimulations])

  // DO NOT CHANGE THE ORDER: This hook must be the last HookEffect
  useEffect(() => {
    clearLoanSimulation()
  }, [])

  return {
    t,
    hasOpenInstallment,
    fetchLoanSimulationsError,
  }
}

export {useCalculator}
