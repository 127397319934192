import axios from 'axios'
import humps from 'humps'
import {config} from '../../config'
import {correlationId} from './../../helpers/correlationId'

const CancelToken = axios.CancelToken
const apiUrl = config.apiGateway.apiUrl
const apiVersion = config.apiGateway.apiVersion
const api = axios.create({
  baseURL: apiUrl,
})

const authUrl = config.apiGateway.authUrl
const authApi = axios.create({
  baseURL: authUrl,
})

authApi.interceptors.request.use(request => {
  if (request.data) {
    const dataSerialized = humps.decamelizeKeys(request.data)
    request.data = dataSerialized
  }

  return request
})

authApi.interceptors.response.use(response => {
  const dataSerialized = humps.camelizeKeys(response.data)
  response.data = dataSerialized

  return response
})

api.interceptors.request.use(request => {
  request.headers['X-Correlation-Id'] = correlationId
  request.headers.Accept = apiVersion

  return request
})

export {api, authApi, apiUrl, CancelToken}
