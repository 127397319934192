const closeWebviewMessage = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage('CLOSE_WINDOW')
  }
}

const firstPageMessage = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage('FIRST_PAGE')
  }
}

const leavingFirstPageMessage = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage('LEAVING_FIRST_PAGE')
  }
}

const fallbackToLocalTopUp = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage('FALLBACK_TO_LOCAL_TOP_UP')
  }
}

export {
  closeWebviewMessage,
  firstPageMessage,
  leavingFirstPageMessage,
  fallbackToLocalTopUp,
}
