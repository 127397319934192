import {createReducer} from 'redux-act'
import {actions} from './loanConditions.actions'

const STATE_KEY = 'loanConditions'
const initialState = {isFetchingLoanSimulations: false, isFetchingLimits: false}

const loanConditionsReducers = createReducer(
  {
    [actions.setLoanAmount]: (state, {loanAmount}) => {
      return {...state, loanAmount}
    },
    [actions.setLoanTerm]: (state, {loanTerm}) => {
      return {...state, loanTerm}
    },
    [actions.fetchLoanSimulations]: state => {
      return {
        ...state,
        isFetchingLoanSimulations: true,
      }
    },
    [actions.fetchLoanSimulationsSuccess]: (
      state,
      {minLoanTerm, maxLoanTerm, loanSimulations},
    ) => {
      return {
        ...state,
        isFetchingLoanSimulations: false,
        loanSimulations,
        maxLoanTerm,
        minLoanTerm,
      }
    },
    [actions.fetchLoanSimulationsError]: state => {
      return {
        ...state,
        isFetchingLoanSimulations: false,
        fetchLoanSimulationsError: true,
      }
    },
    [actions.fetchLimits]: state => {
      return {
        ...state,
        isFetchingLimits: true,
        limits: null,
      }
    },
    [actions.fetchLimitsSuccess]: (state, {limits}) => {
      return {...state, limits, isFetchingLimits: false}
    },
    [actions.fetchLimitsError]: state => {
      return {
        ...state,
        isFetchingLimits: false,
        fetchLimitError: true,
      }
    },
    [actions.setSelectedSimulation]: (state, {selectedSimulation}) => {
      return {...state, selectedSimulation}
    },
    [actions.setProductType]: (state, {productType}) => {
      return {...state, productType}
    },
    [actions.clearLoanSimulation]: state => {
      return {
        ...state,
        loanSimulations: null,
        maxLoanTerm: null,
        minLoanTerm: null,
        selectedSimulation: null,
      }
    },
  },
  initialState,
)

const registerLoanConditionsReducer = () => ({
  [STATE_KEY]: loanConditionsReducers,
})

const selectorLoanConditions = state => state[STATE_KEY]

export {selectorLoanConditions, registerLoanConditionsReducer}
