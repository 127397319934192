import React from 'react'
import {Typography} from '@creditas/typography'
import infoIcon from './info_icon.png'
import chevronRight from './chevron_right.png'
import {Box} from './../../../components/Box'
import {useFaq} from './useFaq.hook'

const Faq = () => {
  const {t} = useFaq()

  return (
    <Box
      backgroundColor="#ffffff"
      margin="xs sm sm sm"
      padding="sm"
      display="flex"
      flexGrow="1"
      radius="medium"
      shadow
      onClick={() => {
        window.location.href = 'https://ajuda.creditas.com.br/'
      }}
    >
      <Box fullWidth display="flex" alignItems="center">
        <Box margin="sm sm sm xs" style={{width: '40px', height: '40px'}}>
          <img src={infoIcon} alt="Ícone de Ajuda" />
        </Box>
        <Typography variant="paragraph">{t(`text.faq`)}</Typography>
        <img src={chevronRight} alt="Seta para a direita" />
      </Box>
    </Box>
  )
}

export {Faq}
