import {useCallback} from 'react'
import {useMappedState} from 'redux-react-hook'
import {selectorApplication} from './application.reducer'

const useApplicationState = () => {
  const {
    additionalCosts,
    financedAmount,
    requestedAmount,
    amountToBePaid,
    bankData,
    cet,
    contractLink,
    firstInstallmentDueDate,
    gracePeriod,
    platformId,
    installmentAmount,
    installmentsExpirationDay,
    interestRate,
    iof,
    ongoingLoans,
    tac,
    term,
  } = useMappedState(useCallback(selectorApplication))

  return {
    additionalCosts,
    financedAmount,
    requestedAmount,
    amountToBePaid,
    bankData,
    cet,
    contractLink,
    firstInstallmentDueDate,
    gracePeriod,
    platformId,
    installmentAmount,
    installmentsExpirationDay,
    interestRate,
    iof,
    ongoingLoans,
    tac,
    term,
  }
}

export {useApplicationState}
