import React from 'react'
import {withRouter} from 'react-router'
import {PropTypes} from 'prop-types'
import {SimulationSummary as BaseSimulationSummary} from './../../components/SimulationSummary'
import {useSimulationSummary} from './useSimulationSummary.hook'

const propTypes = {
  history: PropTypes.any,
}

const SimulationSummary = ({history}) => {
  const {product, onSubmit, onGoBack} = useSimulationSummary({history})

  return (
    <BaseSimulationSummary
      product={product}
      onSubmit={onSubmit}
      onGoBack={onGoBack}
    />
  )
}

SimulationSummary.propTypes = propTypes

const SimulationSummaryWithRouter = withRouter(SimulationSummary)
export {SimulationSummaryWithRouter as SimulationSummary}
