import {css} from '@creditas/stylitas'

const feedbackMessageContainer = ({theme}) => css`
  position: fixed;
  top: 0px
  left: 0px;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 8px;
  border: solid 1px ${theme.bColorneutral03};
  border-radius: 4px;
  background: ${theme.bColorneutral01};
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(170, 187, 187, 0.5);

  > div {
    flex-shrink: 0;
  }
`

const feedbackMessage = ({theme, variant}) => {
  const color = `bColor${variant.toLowerCase()}`

  return css`
    border-top: 8px solid ${theme[color]};
  `
}
export {feedbackMessageContainer, feedbackMessage}
