const adaptApplication = ({
  additionalCosts,
  financedAmount,
  requestedAmount,
  amountToBePaid,
  bankData,
  cet,
  contractLink,
  firstInstallmentDueDate,
  gracePeriod,
  platformId,
  installmentAmount,
  installmentsExpirationDay,
  interestRate,
  iof,
  ongoingLoans,
  tac,
  term,
}) => {
  return {
    additionalCosts,
    financedAmount,
    requestedAmount,
    amountToBePaid,
    bankData,
    cet,
    contractLink,
    firstInstallmentDueDate,
    gracePeriod,
    platformId,
    installmentAmount,
    installmentsExpirationDay,
    interestRate,
    iof,
    ongoingLoans,
    tac,
    term,
  }
}

export {adaptApplication}
