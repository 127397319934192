import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {styled} from '@creditas/stylitas'
import {Box} from './../Box'
import closeIcon from './close_icon.png'
import {modalWrapper} from './modal.style'

const propTypes = {
  children: PropTypes.any,
  closeable: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  fullscreen: PropTypes.any,
}

const ModalWrapper = styled.div`
  ${modalWrapper}
`
const BackgroundFade = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Modal = ({
  isVisible,
  children,
  closeable = true,
  onClose,
  fullscreen,
}) => {
  const [displayModal, setDisplayModal] = useState(isVisible)

  useEffect(() => {
    setDisplayModal(isVisible)
  }, [isVisible])

  const closeModal = () => {
    setDisplayModal(false)
    onClose()
  }

  return displayModal === true ? (
    <BackgroundFade>
      <ModalWrapper fullscreen={fullscreen}>
        {closeable && (
          <Box
            display="flex"
            flexGrow="0"
            justifyContent="flex-end"
            onClick={closeModal}
          >
            <img src={closeIcon} alt="Botão de fechar" />
          </Box>
        )}
        <Box className="modal-content" display="flex" flexGrow="1">
          {children}
        </Box>
      </ModalWrapper>
    </BackgroundFade>
  ) : null
}

Modal.propTypes = propTypes

export {Modal}
