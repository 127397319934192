import {css} from '@creditas/stylitas'
import {utilities} from './../../utils/cssHelpers'

const HorizontalLineStyle = ({
  margin,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
}) => css`
  width: 100%;
  height: 2px;
  border-bottom: 1px solid #eeeeee;

  ${margin && utilities.margin.all(margin)};
  ${marginBottom
    ? utilities.margin.bottom(marginBottom)
    : 'margin-bottom: 24px'};
  ${marginTop ? utilities.margin.top(marginTop) : 'margin-top: 24px'};
  ${marginLeft && utilities.margin.left(marginTop)};
  ${marginRight && utilities.margin.right(marginTop)};
`
export {HorizontalLineStyle}
