import yup from './../../../utils/validator'
import {formatMoney} from './../../../utils/formatMoney'

const validationSchema = ({
  minLoanAmount,
  maxLoanAmount,
  minLoanTerm,
  maxLoanTerm,
}) => {
  return yup.object().shape({
    loanValue: yup
      .string()
      .when('rawLoanValue', {
        is: val => val > maxLoanAmount,
        then: yup
          .string()
          .test(
            'foo',
            `O valor máximo disponível é de ${formatMoney(maxLoanAmount)}`,
            () => false,
          ),
      })
      .when('rawLoanValue', {
        is: val => val < minLoanAmount,
        then: yup
          .string()
          .test(
            'foo',
            `Valor mínimo de ${formatMoney(minLoanAmount)}`,
            () => false,
          ),
      })
      .required(),
    loanTerm: yup
      .string()
      .test('foo', `Nº mínimo de parcelas: ${minLoanTerm}`, val => {
        return !(parseInt(val, 10) < minLoanTerm)
      })
      .test(
        'foo',
        `Nº máximo de parcelas: ${maxLoanTerm}`,
        val => !(parseInt(val, 10) > maxLoanTerm),
      )
      .required(),
  })
}

export {validationSchema}
