import {useCallback} from 'react'
import {useDispatch} from 'redux-react-hook'
import * as featureTogglesActions from './featureToggles.actions'

const useFeatureTogglesActions = () => {
  const dispatch = useDispatch()

  const fetchFeatureToggles = useCallback(
    () => featureTogglesActions.fetchFeatureToggles()(dispatch),
    [dispatch],
  )

  const setFeatureToggles = useCallback(
    ({featureToggles}) =>
      featureTogglesActions.setFeatureToggles({featureToggles})(dispatch),
    [dispatch],
  )

  return {
    fetchFeatureToggles,
    setFeatureToggles,
  }
}

export {useFeatureTogglesActions}
