import {routes} from './../../constants'
import {useApplicationState} from './../../store/application/useApplicationState.hook'

const useTaxesDetails = ({history}) => {
  const {platformId} = useApplicationState()

  const onGoBack = () =>
    history.push(routes.naeSimulationSummary({applicationId: platformId}))

  return {
    onGoBack,
  }
}

export {useTaxesDetails}
