import React from 'react'
import Proptypes from 'prop-types'
import {Typography} from '@creditas/typography'
import {Box} from './../../../components/Box'
import {LoadingPanelAnimation} from './../../../components/LoadingPanelAnimation'
import {useExport} from './useExport.hook'
import {HorizontalLine} from './../../../components/HorizontalLine'
import {DownloadIcon} from './DownloadIcon'

const Export = ({isLoading}) => {
  const {t} = useExport()

  return (
    <Box
      backgroundColor="#ffffff"
      margin="xs sm sm sm"
      padding="sm"
      display="flex"
      flexGrow="1"
      radius="medium"
      shadow
    >
      {isLoading ? (
        <Box
          fullWidth
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingPanelAnimation />
        </Box>
      ) : (
        <Box fullWidth>
          <Box>
            <Typography variant="form">{t(`text.saveContract`)}</Typography>
          </Box>
          <Box marginTop="xs">
            <Typography variant="paragraph">
              {t('text.moreInfoContract')}
            </Typography>
          </Box>
          <HorizontalLine marginTop="sm" marginBottom="xs" />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            onClick={() => null}
            marginTop="10px"
          >
            <Typography variant="action" style={{textDecoration: 'underline'}}>
              {t('links.sendContract')}
            </Typography>
            <Box margin="3px 0 0 xs">
              <DownloadIcon color="#3377BB" width="14px" height="14px" />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

Export.propTypes = {
  isLoading: Proptypes.bool,
}

export {Export}
