import React from 'react'
import {string, oneOf, node} from 'prop-types'
import {styled} from '@creditas/stylitas'
import {
  IconSuccess,
  IconWarningCircle,
  IconWarning,
  IconInfoDefault,
} from '@creditas/icons'
import {feedbackMessageContainer, feedbackMessage} from './feedbackPopUp.style'

const propTypes = {
  className: string,
  children: node.isRequired,
  variant: oneOf(['success', 'danger', 'info', 'warning']).isRequired,
}

const IconError = () => <IconWarning color="#d22" />

const variantIcon = {
  success: IconSuccess,
  warning: IconWarningCircle,
  danger: IconError,
  info: IconInfoDefault,
}

const RootContainer = styled.div`
  ${feedbackMessageContainer};
  ${feedbackMessage};
`

const FeedbackPopUp = ({className, variant, children, ...other}) => {
  const Icon = variantIcon[variant]

  return (
    <RootContainer variant={variant} className={className} {...other}>
      <Icon />
      {children}
    </RootContainer>
  )
}

FeedbackPopUp.propTypes = propTypes

FeedbackPopUp.displayName = 'FeedbackPopUp'

export {FeedbackPopUp}
