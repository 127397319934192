import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useApplicationState} from './../../../store/application/useApplicationState.hook'

const useBankInfo = () => {
  const {t} = useTranslation(['summary', 'common'])
  const [isVisible, setIsVisible] = useState(false)
  const {bankData} = useApplicationState()

  const bank = bankData && bankData.bank
  const account = bankData && bankData.account
  const branch = bankData && bankData.branch
  const accountType = bankData && bankData.accountType

  return {bank, account, branch, accountType, t, isVisible, setIsVisible}
}

export {useBankInfo}
