import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {
  closeWebviewMessage,
  leavingFirstPageMessage,
} from '../../helpers/webviewMessages'
import {setSessionTokenFromUrl} from './../../helpers/sessionToken'
import {useSessionToken} from './../../hooks/useSessionToken.hook'
import {products, routes} from './../../constants'
import {useLoanConditionsState} from './../../store/loanConditions/useLoanConditionsState.hook'
import {useApplicationActions} from './../../store/application/useApplicationActions.hook'
import {createApplication} from './../../services/api/application/create'

const useCalculator = ({history, location}) => {
  const {t} = useTranslation(['calculator', 'common'])

  const product = products.newAutoEquity
  const allowCustomLoanTerm = true
  const loanTermOptions = [24, 36, 48, 60]
  const maxLoanAmount = 150000
  const minLoanAmount = 1000

  const {sessionToken} = useSessionToken()
  const {selectedSimulation} = useLoanConditionsState()
  const {setApplicationInfo} = useApplicationActions()

  const onSubmit = () => {
    createApplication(setApplicationInfo)({
      chosenProposal: selectedSimulation,
      product,
      sessionToken,
    }).then(({platformId}) => {
      leavingFirstPageMessage()
      history.push(routes.naeSimulationSummary({applicationId: platformId}))
    })
  }

  useEffect(() => {
    setSessionTokenFromUrl({location})
  }, [location])

  return {
    allowCustomLoanTerm,
    loanTermOptions,
    maxLoanAmount,
    minLoanAmount,
    onGoBack: closeWebviewMessage,
    onSubmit,
    product,
    t,
  }
}

export {useCalculator}
