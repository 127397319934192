import axios from 'axios'
import {api} from '../api'

const createTopUpRequest = ({sessionToken, selectedSimulation}) => {
  return api
    .post(`/top-up-requests`, selectedSimulation, {
      headers: {Authorization: `Bearer ${sessionToken}`},
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error
      }
    })
}

export {createTopUpRequest}
