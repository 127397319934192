import React from 'react'
import {Typography} from '@creditas/typography'
import {Button} from '@creditas/button'
import {Modal} from './../Modal'
import {Box} from './../Box'
import {fallbackToLocalTopUp} from './../../helpers/webviewMessages'
import {useFatalErrorModal} from './useFatalErrorModal.hook'

const FatalErrorModal = () => {
  const {t} = useFatalErrorModal()
  return (
    <Modal isVisible={true} closeable={false}>
      <Box display="flex" flexDirection="column">
        <Box marginTop="xs">
          <Typography variant="button" gutterBottom>
            {t(`headings.fatalError`)}
          </Typography>
        </Box>
        <Box marginBottom="24px">
          <Typography variant="support">
            {t(`content.fatalError`)}
            <a href="https://www.creditas.com/canais-de-atendimento">
              <Typography
                style={{display: 'inline', textDecoration: 'underline'}}
                variant="action"
              >
                {t(`action.fatalError`)}
              </Typography>
            </a>
          </Typography>
        </Box>
        <Box marginBottom="xs">
          <Button fullWidth onClick={() => fallbackToLocalTopUp()}>
            {t(`common:links.understood`)}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export {FatalErrorModal}
