import {createAction} from 'redux-act'

const actions = {
  setApplicationInfo: createAction('set the application Info'),
}

const setApplicationInfo = applicationInfo => {
  return dispatch => {
    dispatch(actions.setApplicationInfo(applicationInfo))
  }
}

export {setApplicationInfo, actions}
