import React from 'react'
import {Typography} from '@creditas-ui/typography'
import {TextField} from '@creditas/input'
import {ButtonPrimaryTextOnly} from '@creditas-ui/button'
import {Flex} from '@creditas-ui/layout'
import PropTypes from 'prop-types'
import {Box} from './../Box'

const propTypes = {
  allowCustomLoanTerm: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  optionLabel: PropTypes.any,
  optionStatus: PropTypes.any,
  setFieldValue: PropTypes.any,
  currentValue: PropTypes.any,
  label: PropTypes.string,
  helperText: PropTypes.string,
  labelTextColor: PropTypes.string,
}

const selectedButtonStyle = {
  minWidth: '64px',
  width: '64px',
  padding: '0',
  border: '15px solid #1F2D27',
  color: '#49E295',
  fontSize: '16px',
  backgroundColor: '#1F2D27',
  marginRight: '15px',
  boxShadow: 'none',
}

const enabledButtonCustomStyle = {
  minWidth: '64px',
  width: '64px',
  padding: '0',
  border: '15px solid #EDF0EF',
  color: '#6F7974',
  fontSize: '16px',
  backgroundColor: '#EDF0EF',
  marginRight: '15px',
}

const TextFieldWithOptions = ({
  allowCustomLoanTerm,
  options,
  optionStatus,
  setFieldValue,
  optionLabel,
  currentValue,
  labelTextColor,
  ...props
}) => {
  return (
    <Box style={{display: 'grid'}}>
      {allowCustomLoanTerm ? (
        <TextField {...props} />
      ) : (
        <Box marginTop="0" marginBottom="sm">
          <Typography color={labelTextColor} variant="bodyMdRegular">
            {props.label}
          </Typography>
          <Typography variant="bodySmLight">{props.helperText}</Typography>
          <Box style={{display: 'none'}}>
            <TextField {...props} />
          </Box>
        </Box>
      )}
      <Flex
        justifyContent="space-between"
        margin="0 0 0 0"
        alignItems="center"
        style={{overflowX: 'scroll'}}
        width="100%"
        paddingBottom="15px"
      >
        {options.map((option, index) => {
          const buttonStyle = () => {
            if (option === currentValue) {
              return selectedButtonStyle
            } else if (optionStatus(option)) {
              return enabledButtonCustomStyle
            }
            return null
          }

          if (optionStatus(option)) {
            return (
              <Box display="flex" justifyContent="space-between" key={index}>
                <ButtonPrimaryTextOnly
                  type="button"
                  secondary
                  style={buttonStyle()}
                  onClick={() => {
                    setFieldValue(option)
                  }}
                  disabled={!optionStatus(option)}
                >
                  {optionLabel(option)}
                </ButtonPrimaryTextOnly>
              </Box>
            )
          } else {
            return null
          }
        })}
      </Flex>
    </Box>
  )
}

TextFieldWithOptions.propTypes = propTypes

export {TextFieldWithOptions}
