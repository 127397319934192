import {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {closeWebviewMessage} from '../../helpers/webviewMessages'
import {setSessionTokenFromUrl} from './../../helpers/sessionToken'
import {useSessionToken} from './../../hooks/useSessionToken.hook'
import {products} from './../../constants'
import {useLoanConditionsState} from './../../store/loanConditions/useLoanConditionsState.hook'
import {createTopUpRequest} from './../../services/api/top-up-request/create'
import {dispatchUserAgreedToSimulationAction} from './../../utils/analyticalEvents'

const useCalculator = ({location, history}) => {
  const {t} = useTranslation(['calculatorAutoEquity', 'common'])

  const allowCustomLoanTerm = false
  const loanTermOptions = [24, 30, 36, 42, 48, 54, 60]
  const maxLoanAmount = 150000
  const minLoanAmount = 3000
  const product = products.autoEquity

  const {sessionToken} = useSessionToken()
  const {selectedSimulation} = useLoanConditionsState()

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [successMessageIsVisible, setSuccessMessageIsVisible] = useState(false)

  const onSubmit = () => {
    if (!isSubmitted) {
      createTopUpRequest({sessionToken, selectedSimulation}).then(() => {
        const {financedAmount, term} = selectedSimulation

        dispatchUserAgreedToSimulationAction({
          amount: financedAmount.components.requestedAmount,
          installments: term,
        })

        history.push('#success')
        setIsSubmitted(true)
        setSuccessMessageIsVisible(true)
      })
    }
  }

  useEffect(() => {
    setSessionTokenFromUrl({location})
  }, [location])

  return {
    allowCustomLoanTerm,
    loanTermOptions,
    maxLoanAmount,
    minLoanAmount,
    onGoBack: closeWebviewMessage,
    onSubmit,
    product,
    successMessageIsVisible,
    t,
  }
}

export {useCalculator}
