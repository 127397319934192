import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import {Typography} from '@creditas/typography'
import CurrencyFormat from 'react-currency-format'
import {Box} from './../../Box'
import {ScrollableHeader} from './../../ScrollableHeader'
import {HorizontalLine} from './../../HorizontalLine'
import {ScrollToTop} from './../../ScrollToTop'
import {useTaxesDetails} from './useTaxesDetails.hook'
import {formatMoney} from './../../../utils/formatMoney'

const propTypes = {
  onGoBack: PropTypes.func,
}

const TaxesDetails = ({onGoBack}) => {
  const {
    t,
    requestedAmount,
    term,
    installmentAmount,
    iof,
    monthlyInterest,
    cetMonthly,
  } = useTaxesDetails()

  return (
    <Box
      backgroundColor="#f7f7f7"
      height="100%"
      display="flex"
      flexDirection="column"
      style={{minHeight: '100vh'}}
    >
      <ScrollableHeader
        mainArrowColor="#ffffff"
        mainBackgroundColor="#11bb77"
        mainTextColor="#ffffff"
        minimizedArrowColor="#ffffff"
        minimizedBackgroundColor="#11bb77"
        minimizedTextColor="#ffffff"
        navigationOnCLick={onGoBack}
        navigationText={t(`header.linkTitleTaxesDetails`)}
      >
        <Box>
          <Typography variant="paragraph" color="#ffffff">
            <span style={{fontWeight: '600', fontSize: '12px'}}>
              {t(`text.valueToReceive`)}
            </span>
          </Typography>
        </Box>
        <Box margin="0">
          <Typography variant="h4" color="#ffffff">
            <CurrencyFormat
              value={requestedAmount}
              displayType="text"
              prefix={t(`common:labels.currency`)}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
          <Typography variant="supportForm" color="#ffffff">
            {t(`text.installmentsOf`)}
            {t(`text.installmentsDisplay`, {
              loanTerm: term,
              installmentValue: formatMoney(installmentAmount),
            })}
          </Typography>
        </Box>
      </ScrollableHeader>
      <Box backgroundColor="#ffffff" padding="md sm sm sm">
        <Typography variant="h6">{t(`text.taxes`)}</Typography>
        <Box display="flex" justifyContent="space-between" marginTop="sm">
          <Typography
            variant="h6"
            style={{fontSize: '16px', fontWeight: '600'}}
          >
            {t(`text.interestRate`)}
          </Typography>
          <Typography
            variant="h6"
            style={{fontSize: '16px', fontWeight: '600'}}
          >
            {t(`text.monthlyPercentage`, {
              percentage: (monthlyInterest * 100).toFixed(2),
            })}
          </Typography>
        </Box>

        <HorizontalLine />

        <Typography variant="h6">{t(`text.governmentTaxes`)}</Typography>
        <Box display="flex" justifyContent="space-between" marginTop="sm">
          <Typography
            variant="h6"
            style={{fontSize: '16px', fontWeight: '600'}}
          >
            {t(`text.iof`)}
          </Typography>
          <Typography
            variant="h6"
            style={{fontSize: '16px', fontWeight: '600'}}
          >
            <CurrencyFormat
              value={iof}
              displayType="text"
              prefix={t(`common:labels.currency`)}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </Box>
      </Box>
      <Box padding="md sm">
        <Typography variant="h6">{t(`text.totalCosts`)}</Typography>
        <Box display="flex" justifyContent="space-between" marginTop="sm">
          <Box width="60%">
            <Typography
              variant="h6"
              style={{fontSize: '16px', fontWeight: '600'}}
              color="#556666"
            >
              {t(`text.cet`)}
            </Typography>
            <Typography variant="support" style={{fontSize: '14px'}}>
              {t(`text.cetHelper`)}
            </Typography>
          </Box>
          <Typography
            variant="h6"
            style={{fontSize: '16px', fontWeight: '600'}}
          >
            {t(`text.monthlyPercentage`, {
              percentage: (cetMonthly * 100).toFixed(2),
            })}
          </Typography>
        </Box>
      </Box>
      <ScrollToTop />
    </Box>
  )
}

TaxesDetails.propTypes = propTypes

const TaxesDetailsWithRouter = withRouter(TaxesDetails)
export {TaxesDetailsWithRouter as TaxesDetails}
