import React, {useEffect} from 'react'
import {object} from 'prop-types'
import {StoreContext} from 'redux-react-hook'
import {ThemeProvider as DSThemeProvider} from '@creditas-ui/system'
import {standard} from '@creditas-ui/themes'
import {Pages} from './pages'
import {configureStore} from './store'
import {generateCorrelationId} from './helpers/correlationId'

const App = ({store}) => {
  useEffect(() => generateCorrelationId(), [])

  return (
    <StoreContext.Provider value={store}>
      <DSThemeProvider theme={standard}>
        <Pages />
      </DSThemeProvider>
    </StoreContext.Provider>
  )
}

App.propTypes = {store: object}
App.defaultProps = {store: configureStore()}

export {App}
