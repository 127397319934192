import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {registerLoanConditionsReducer} from './loanConditions'
import {registerCustomerReducer} from './customer'
import {registerApplicationReducer} from './application'
import {registerFeatureTogglesReducer} from './featureToggles'

const configureStore = () => {
  const rootReducer = combineReducers({
    ...registerLoanConditionsReducer(),
    ...registerCustomerReducer(),
    ...registerApplicationReducer(),
    ...registerFeatureTogglesReducer(),
  })

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunkMiddleware)),
  )

  return store
}

export {configureStore}
