import React from 'react'
import PropTypes from 'prop-types'
import {Typography} from '@creditas/typography'
import {Button} from '@creditas/button'
import {Modal} from './../../../Modal'
import {Box} from './../../../Box'
import changeBankAccountIcon from './change_bank_account_icon.png'
import {useChangeBankAccount} from './useChangeBankAccount.hook'

const propTypes = {
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
}

const ChangeBankAccount = ({isVisible, setIsVisible}) => {
  const {t} = useChangeBankAccount()
  return (
    <Modal fullscreen isVisible={isVisible} onClose={() => setIsVisible(false)}>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexGrow="1"
          justifyContent="center"
          alignItems="center"
        >
          <img src={changeBankAccountIcon} alt="Ícone de mudar conta" />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box marginBottom="md">
            <Typography variant="h5" style={{textAlign: 'center'}} gutterBottom>
              {t(`text.changeAccount`)}
            </Typography>
            <Typography variant="support" style={{textAlign: 'center'}}>
              {t(`text.changeAccountHelper`)}
            </Typography>
          </Box>
          <Box marginBottom="sm">
            <Button
              secondary
              fullWidth
              onClick={() => {
                window.location.href = 'https://ajuda.creditas.com.br/'
              }}
            >
              {t(`links.contact`)}
            </Button>
          </Box>
          <Button fullWidth onClick={() => setIsVisible(false)}>
            {t(`links.understood`)}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

ChangeBankAccount.propTypes = propTypes

export {ChangeBankAccount}
