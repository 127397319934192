import axios from 'axios'
import {api} from '../api'

const eventDisplayedSimulation = ({
  sessionToken,
  product,
  selectedSimulation,
}) => {
  return api
    .post(
      `/events/displayed-simulations`,
      {product, simulation: selectedSimulation},
      {headers: {Authorization: `Bearer ${sessionToken}`}},
    )
    .then(response => response.data)
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error
      }
    })
}

export {eventDisplayedSimulation}
