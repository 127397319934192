import React from 'react'
import {Typography} from '@creditas/typography'
import {TextField} from '@creditas/input'
import {Button} from '@creditas/button'
import PropTypes from 'prop-types'
import {Box} from './../Box'

const propTypes = {
  allowCustomLoanTerm: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  optionLabel: PropTypes.any,
  optionStatus: PropTypes.any,
  setFieldValue: PropTypes.any,
  currentValue: PropTypes.any,
  label: PropTypes.string,
  helperText: PropTypes.string,
}

const selectedButtonStyle = {
  minWidth: '64px',
  width: '64px',
  padding: '0',
  border: '1px solid #3377bb',
  color: '#ffffff',
  fontSize: '16px',
  backgroundColor: '#3377bb',
}

const enabledButtonCustomStyle = {
  minWidth: '64px',
  width: '64px',
  padding: '0',
  border: '1px solid #334444',
  color: '#334444',
  fontSize: '16px',
  backgroundColor: '#f7f7f7',
}

const disabledButtonCustomStyle = {
  minWidth: '64px',
  width: '64px',
  padding: '0',
  border: '1px solid #cccccc',
  color: '#778888',
  fontSize: '16px',
  backgroundColor: '#cccccc',
}

const TextFieldWithOptions = ({
  allowCustomLoanTerm,
  options,
  optionStatus,
  setFieldValue,
  optionLabel,
  currentValue,
  ...props
}) => {
  return (
    <Box>
      {allowCustomLoanTerm ? (
        <TextField {...props} />
      ) : (
        <Box marginTop="0" marginBottom="md">
          <Typography variant="form">{props.label}</Typography>
          <Typography variant="support">{props.helperText}</Typography>
          <Box style={{display: 'none'}}>
            <TextField {...props} />
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        margin="-16px 0 0 0"
        style={{overflowX: 'scroll'}}
        width="calc(100% + 16px)"
      >
        {options.map((option, index) => {
          const buttonStyle = () => {
            if (option === currentValue) {
              return selectedButtonStyle
            } else if (optionStatus(option)) {
              return enabledButtonCustomStyle
            } else {
              return disabledButtonCustomStyle
            }
          }

          return (
            <Box display="flex" justifyContent="space-between" key={index}>
              <Button
                type="button"
                secondary
                style={buttonStyle()}
                onClick={() => {
                  setFieldValue(option)
                }}
                disabled={!optionStatus(option)}
              >
                {optionLabel(option)}
              </Button>
              {index < options.length - 1 ? (
                <Box width="8px" />
              ) : (
                <Box width="16px" />
              )}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

TextFieldWithOptions.propTypes = propTypes

export {TextFieldWithOptions}
