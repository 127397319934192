import React from 'react'
import {Field as FieldFormik} from 'formik'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  alwaysShowFeedbackText: PropTypes.bool,
  numericKeyboard: PropTypes.bool,
}

const Field = props => {
  const {children} = props

  const _childrenWithProps = propsToApply => {
    delete propsToApply.children
    return React.cloneElement(children, propsToApply)
  }

  return (
    <FieldFormik
      {...props}
      render={({field, form: {submitCount, errors}}) => {
        const error = submitCount > 0 && errors[props.name]
        let customProps = {...props}

        if (props.numericKeyboard === true) {
          customProps = {...customProps, type: 'tel'}
        }

        return _childrenWithProps({
          error,
          feedbackText:
            ((props.alwaysShowFeedbackText && field.value) || error) &&
            errors[props.name],
          ...customProps,
          ...field,
        })
      }}
    />
  )
}

Field.propTypes = propTypes

export {Field}
