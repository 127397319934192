import {useTranslation} from 'react-i18next'
import {useLoanConditionsActions} from './../../../store/loanConditions/useLoanConditionsAction.hook'
import {useLoanConditionsState} from './../../../store/loanConditions/useLoanConditionsState.hook'
import {useCustomerState} from './../../../store/customer/useCustomerState.hook'

const useForm = () => {
  const {t} = useTranslation(['calculator', 'common'])

  const {setLoanAmount, setLoanTerm} = useLoanConditionsActions()
  const {pollingAuthToken} = useCustomerState()
  const {
    isFetchingLimits,
    isFetchingLoanSimulations,
    limits,
    loanAmount,
    loanSimulations,
    loanTerm,
    maxLoanTerm,
    minLoanTerm,
    selectedSimulation,
  } = useLoanConditionsState()

  const initialValues = {
    loanTerm: loanTerm || '',
    loanValue: loanAmount || '',
    rawLoanValue: loanAmount || '',
  }

  return {
    initialValues,
    isFetchingLimits,
    isFetchingLoanSimulations,
    limits,
    loanAmount,
    loanSimulations,
    loanTerm,
    maxLoanTerm,
    minLoanTerm,
    maxPreApprovedLoanAmount: limits && limits.available,
    selectedSimulation,
    setLoanAmount,
    setLoanTerm,
    pollingAuthToken,
    t,
  }
}

export {useForm}
