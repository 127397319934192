import qs from 'query-string'

const sessionToken = window.localStorage.getItem('sessionToken')

const setSessionTokenFromUrl = ({location}) => {
  const sessionTokenFromUrl = qs.parse(location.hash, {
    ignoreQueryPrefix: true,
  }).token

  if (sessionTokenFromUrl) {
    window.localStorage.setItem('sessionToken', sessionTokenFromUrl)
  }
}

export {sessionToken, setSessionTokenFromUrl}
