import {useTranslation} from 'react-i18next'
import {useApplicationState} from './../../../store/application/useApplicationState.hook'

const useOngoingLoan = () => {
  const {t} = useTranslation(['summary', 'common'])
  const {
    ongoingLoans: {totalInstallmentAmount},
  } = useApplicationState()

  return {t, totalInstallmentAmount}
}

export {useOngoingLoan}
