import {styled} from '@creditas/stylitas'
import PropTypes from 'prop-types'
import React from 'react'
import {Box} from './../Box'
import {HorizontalLine} from './../HorizontalLine'
import {RunningBarStyle} from './loadingPanelAnimation.style'

const propTypes = {
  size: PropTypes.string,
}

const RunningBar = styled.div`
  ${RunningBarStyle}
`

const LoadingPanelAnimation = ({size}) => {
  return (
    <Box fullWidth>
      <RunningBar width="46px" height="16px" marginBottom="xs" />
      <RunningBar width="300px" height="20px" marginBottom="sm" />
      <RunningBar width="150px" height="14px" marginBottom="xs" />
      {size === 'lg' && (
        <Box fullWidth>
          <HorizontalLine />
          <RunningBar width="200px" height="16px" marginBottom="xs" />
          <RunningBar width="200px" height="14px" marginBottom="md" />
          <RunningBar width="100px" height="20px" marginBottom="0" />
        </Box>
      )}
    </Box>
  )
}

LoadingPanelAnimation.propTypes = propTypes

export {LoadingPanelAnimation}
