import {css, mq} from '@creditas/stylitas'
import {utilities} from './utilities'

const box = ({
  theme,
  boxSizing = 'border-box',
  shadow,
  display,
  flexDirection,
  alignItems,
  justifyContent,
  height,
  radius,
  padding,
  paddingBottom,
  paddingTop,
  margin,
  marginBottom,
  marginTop,
  width,
  backgroundColor,
  background,
  flexGrow,
  fullWidth,
}) =>
  css`
    ${mq({
      boxSizing,
      display,
      flexDirection,
      justifyContent,
      alignItems,
      height,
      width,
      backgroundColor,
      background,
      flexGrow,
    })}

    ${padding && utilities.padding.all(padding)};
    ${paddingBottom && utilities.padding.bottom(paddingBottom)};
    ${paddingTop && utilities.padding.top(paddingTop)};
    ${margin && utilities.margin.all(margin)};
    ${marginBottom && utilities.margin.bottom(marginBottom)};
    ${marginTop && utilities.margin.top(marginTop)};
    ${shadow && utilities.surface.shadow({theme})};
    ${radius && utilities.radius[radius]({theme})};
    ${fullWidth && 'width: 100%'};
  `

export {box}
