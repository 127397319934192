/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {useFormikContext, Formik, Form as FormFormik} from 'formik'
import {withRouter} from 'react-router'
import {Button} from '@creditas/button'
import {Typography} from '@creditas/typography'
import {IconFileLoader} from '@creditas/icons'
import {CurrencyTextField} from '../../TextField/TextField'
import {Box} from './../../Box'
import {Field} from './../../Field'
import {GridItem, GridContainer} from './../../Grid'
import {HorizontalLine} from './../../HorizontalLine'
import {TextFieldWithOptions} from './../../TextField'
import {formatMoney} from './../../../utils/formatMoney'
import {useForm} from './useForm.hook'
import {validationSchema} from './validation-schema'

const propTypes = {
  hasOpenInstallment: PropTypes.bool,
  loanTermOptions: PropTypes.array,
  location: PropTypes.any,
  allowCustomLoanTerm: PropTypes.bool,
  minLoanAmount: PropTypes.number,
  maxLoanAmount: PropTypes.number,
  onSubmit: PropTypes.func,
  displaySimulationHelperMessage: PropTypes.bool,
  translation: PropTypes.any,
}

const UpdateStore = ({location}) => {
  const {values, validateForm} = useFormikContext()
  const {setLoanAmount, setLoanTerm} = useForm({location})

  useEffect(() => {
    validateForm()

    setLoanAmount({loanAmount: values.rawLoanValue})
    setLoanTerm({loanTerm: values.loanTerm})
  }, [values]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

// eslint-disable-next-line max-lines-per-function
const Form = ({
  allowCustomLoanTerm,
  hasOpenInstallment,
  loanTermOptions,
  location,
  maxLoanAmount,
  minLoanAmount,
  onSubmit,
  displaySimulationHelperMessage,
  translation,
}) => {
  const {
    initialValues,
    isFetchingLoanSimulations,
    isFetchingLimits,
    loanAmount,
    limits,
    loanSimulations,
    maxLoanTerm,
    maxPreApprovedLoanAmount,
    minLoanTerm,
    pollingAuthToken,
    selectedSimulation,
  } = useForm({location})

  const t = translation
  const loanAmountRef = useRef()

  useLayoutEffect(() => {
    if (loanAmountRef.current) {
      const element = loanAmountRef.current.querySelector('[name="loanValue"]')

      element.setAttribute('autofocus', 'autofocus')
      element.focus()
    }
  }, [loanAmountRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  const forbiddenToProcceed = ({isValid}) => {
    return hasOpenInstallment || !selectedSimulation || !isValid
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema({
        maxLoanAmount: maxPreApprovedLoanAmount,
        minLoanAmount,
        minLoanTerm,
        maxLoanTerm,
      })}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {formProps => {
        return (
          <FormFormik
            style={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '100%',
            }}
          >
            <UpdateStore />
            <Box flexGrow="1">
              <GridContainer marginBottom="0px">
                {(isFetchingLimits || pollingAuthToken) && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconFileLoader />
                  </Box>
                )}
                {limits && (
                  <GridItem ref={loanAmountRef} startCol={1} endCol={13}>
                    <Field
                      type="text"
                      name="loanValue"
                      alwaysShowFeedbackText={true}
                      numericKeyboard={true}
                      error={
                        formProps.values.rawLoanValue &&
                        formProps.values.rawLoanValue < minLoanAmount
                      }
                      warning={
                        formProps.values.rawLoanValue &&
                        formProps.values.rawLoanValue > limits.available
                      }
                    >
                      <CurrencyTextField
                        label={t('calculator:form.labels.loanValue')}
                        placeholder={t('form.placeholders.loanValue')}
                        helperText={t('form.supportText.loanValue', {
                          maxLoanValue: formatMoney(limits.available),
                        })}
                        onValueChange={values => {
                          const {floatValue} = values
                          formProps.setFieldValue('rawLoanValue', floatValue)
                        }}
                      />
                    </Field>
                  </GridItem>
                )}

                {isFetchingLoanSimulations &&
                  loanAmount >= minLoanAmount &&
                  loanAmount <= maxLoanAmount && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <IconFileLoader />
                    </Box>
                  )}

                {loanAmount && loanSimulations && (
                  <GridItem startCol={1} endCol={13}>
                    <HorizontalLine />
                    <Field
                      type="text"
                      name="loanTerm"
                      alwaysShowFeedbackText={true}
                      numericKeyboard={true}
                      error={
                        formProps.submitCount === 0 &&
                        formProps.values.loanTerm &&
                        (formProps.values.loanTerm > maxLoanTerm ||
                          formProps.values.loanTerm < minLoanTerm)
                      }
                    >
                      <TextFieldWithOptions
                        label={t('calculator:form.labels.loanTerm')}
                        placeholder={t('form.placeholders.loanTerm')}
                        helperText={t('form.supportText.loanTerm', {
                          minTerm: minLoanTerm,
                          maxTerm: maxLoanTerm,
                        })}
                        allowCustomLoanTerm={allowCustomLoanTerm}
                        options={loanTermOptions}
                        optionLabel={option => {
                          return `${option}x`
                        }}
                        optionStatus={option => {
                          return option >= minLoanTerm && option <= maxLoanTerm
                        }}
                        setFieldValue={value => {
                          return formProps.setFieldValue('loanTerm', value)
                        }}
                        currentValue={formProps.values.loanTerm}
                      />
                    </Field>
                  </GridItem>
                )}

                {selectedSimulation && (
                  <GridItem startCol={1} endCol={13} marginBottom="0px">
                    <HorizontalLine />
                    <Box>
                      <Typography variant="body2" color="#778888">
                        <span style={{fontSize: '14px', fontWeight: '600'}}>
                          {t('calculator:text.currentOutstanding', {
                            outstanding: formatMoney(limits.taken),
                          })}
                        </span>
                      </Typography>
                    </Box>
                    <Box marginBottom="5px">
                      <Typography variant="body2" color="#778888">
                        <span style={{fontSize: '14px', fontWeight: '600'}}>
                          {t('calculator:text.requestedValue', {
                            requestedValue: formatMoney(
                              formProps.values.rawLoanValue,
                            ),
                          })}
                        </span>
                      </Typography>
                    </Box>
                    <Box marginBottom="5px">
                      <Typography color="#778888" variant="body2">
                        <span style={{fontSize: '12px', fontWeight: '600'}}>
                          {t('calculator:text.installmentValue')}
                        </span>
                      </Typography>
                    </Box>
                    <Box marginBottom="5px">
                      <Typography variant="h5" color="#11bb77">
                        {t('calculator:text.loanSummary', {
                          loanTerm: selectedSimulation.term,
                          loanInstallment: formatMoney(
                            selectedSimulation.installmentAmount,
                          ),
                        })}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        <span style={{fontSize: '14px'}}>
                          {t('calculator:text.installmentInterestRate', {
                            interestRate:
                              selectedSimulation.interestRate.monthly * 100,
                          })}
                        </span>
                      </Typography>
                    </Box>
                    {displaySimulationHelperMessage && (
                      <Box>
                        <Box marginTop="xs">
                          <Typography variant="supportForm">
                            {t('text.simulationHelper')}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </GridItem>
                )}
              </GridContainer>
            </Box>
            <GridContainer marginBottom="0px">
              <GridItem startCol={1} endCol={13} marginBottom="0px">
                <HorizontalLine />
                <Button
                  isLoading={formProps.submitCount > 0}
                  disabled={forbiddenToProcceed(formProps)}
                  fullWidth
                  type="submit"
                >
                  {t('links.agree')}
                </Button>
                <Box display="flex" justifyContent="center" marginTop="sm">
                  <Typography variant="supportForm" textAlign="center">
                    <span style={{fontSize: '14px'}}>
                      {t('text.nextStepMessage')}
                    </span>
                  </Typography>
                </Box>
              </GridItem>
            </GridContainer>
          </FormFormik>
        )
      }}
    </Formik>
  )
}

Form.propTypes = propTypes

const FormWithRouter = withRouter(Form)
export {FormWithRouter as Form}
