const AUTO_EQUITY_BASE_PATH = 'auto-equity'
const NEW_AUTO_EQUITY_BASE_PATH = 'new-auto-equity'

const products = {
  newAutoEquity: 'NEW_AUTO_EQUITY',
  autoEquity: 'AUTO_EQUITY',
}

const routes = {
  aeCalculator: `/${AUTO_EQUITY_BASE_PATH}/calculadora`,
  naeCalculator: `/${NEW_AUTO_EQUITY_BASE_PATH}/calculadora`,
  naeSimulationSummary: ({applicationId}) =>
    `/${NEW_AUTO_EQUITY_BASE_PATH}/resumo/${applicationId}`,
  naeTaxesDetails: `/${NEW_AUTO_EQUITY_BASE_PATH}/detalhes-da-proposta`,
}

const defaultFeatureFlags = {
  sampleFlag: 'off',
}

export {products, routes, defaultFeatureFlags}
