import {useTranslation} from 'react-i18next'
import {useApplicationState} from './../../../store/application/useApplicationState.hook'

const useTaxesDetails = () => {
  const {t} = useTranslation(['summary', 'common'])
  const {
    requestedAmount,
    term,
    installmentAmount,
    iof,
    interestRate,
    cet,
  } = useApplicationState()

  return {
    t,
    requestedAmount,
    term,
    installmentAmount,
    iof,
    monthlyInterest: interestRate.monthly,
    cetMonthly: cet.monthly,
  }
}

export {useTaxesDetails}
