import {useCallback} from 'react'
import {useDispatch} from 'redux-react-hook'
import * as customer from './customer.actions'

const useCustomerActions = () => {
  const dispatch = useDispatch()
  const setCustomerInfo = useCallback(
    customerInfo => customer.setCustomerInfo(customerInfo)(dispatch),
    [dispatch],
  )

  return {setCustomerInfo}
}

export {useCustomerActions}
