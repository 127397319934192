import React from 'react'
import {css, styled} from '@creditas/stylitas'
import {IconLoader} from '@creditas/icons'
import PropTypes from 'prop-types'

const wrapper = () => css`
  font-size: 5em;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  ${wrapper}
`

const Loader = ({children}) => {
  return (
    <Wrapper>
      <IconLoader />
      {children}
    </Wrapper>
  )
}

Loader.propTypes = {
  children: PropTypes.node,
}

export {Loader}
