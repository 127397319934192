import {routes, products} from './../../constants'

const useSimulationSummary = ({history}) => {
  const product = products.newAutoEquity

  const onSubmit = () => null
  const onGoBack = () => history.push(routes.naeCalculator)

  return {
    onGoBack,
    onSubmit,
    product,
  }
}

export {useSimulationSummary}
