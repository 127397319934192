import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import {Calculator as NewExperienceBaseCalculator} from './../../components/NewExperienceCalculator'
import {SuccessMessage as NewExperienceSuccessMessage} from './NewExperienceSucessMessage'
import {useCalculator} from './useCalculator.hook'
import {dispatchPageViewEvent} from './../../utils/analyticalEvents'

const propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
}

const Calculator = ({location, history}) => {
  const {
    allowCustomLoanTerm,
    loanTermOptions,
    maxLoanAmount,
    minLoanAmount,
    onGoBack,
    onSubmit,
    product,
    successMessageIsVisible,
    t,
  } = useCalculator({location, history})

  React.useEffect(() => {
    dispatchPageViewEvent()
  })

  return (
    <div>
      <NewExperienceBaseCalculator
        allowCustomLoanTerm={allowCustomLoanTerm}
        loanTermOptions={loanTermOptions}
        maxLoanAmount={maxLoanAmount}
        minLoanAmount={minLoanAmount}
        onGoBack={onGoBack}
        onSubmit={onSubmit}
        product={product}
        displaySimulationHelperMessage={true}
        translation={t}
      />
      <NewExperienceSuccessMessage isVisible={successMessageIsVisible} />
    </div>
  )
}

Calculator.propTypes = propTypes

const CalculatorWithRouter = withRouter(Calculator)
export {CalculatorWithRouter as Calculator}
