import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import {Typography} from '@creditas/typography'
import {Form} from './Form'
import {useCalculator} from './useCalculator.hook'
import {Box} from './../Box'
import {FeedbackPopUp} from './../FeedbackPopUp'
import {ScrollableHeader} from './../ScrollableHeader'
import {ScrollToTop} from './../ScrollToTop'
import {FatalErrorModal} from './../FatalErrorModal'

const propTypes = {
  allowCustomLoanTerm: PropTypes.bool,
  loanTermOptions: PropTypes.array,
  location: PropTypes.any,
  history: PropTypes.any,
  minLoanAmount: PropTypes.number,
  maxLoanAmount: PropTypes.number,
  nextStepMessage: PropTypes.string,
  onGoBack: PropTypes.func,
  onSubmit: PropTypes.func,
  product: PropTypes.string,
  displaySimulationHelperMessage: PropTypes.bool,
  translation: PropTypes.any,
}

const Calculator = ({
  allowCustomLoanTerm,
  history,
  loanTermOptions,
  location,
  maxLoanAmount,
  minLoanAmount,
  nextStepMessage,
  onGoBack,
  onSubmit,
  product,
  displaySimulationHelperMessage,
  translation,
}) => {
  const {t, hasOpenInstallment, fetchLoanSimulationsError} = useCalculator({
    history,
    location,
    maxLoanAmount,
    minLoanAmount,
    product,
  })

  return (
    <Box
      backgroundColor="#f7f7f7"
      height="100%"
      display="flex"
      flexDirection="column"
      style={{minHeight: '100vh'}}
    >
      {fetchLoanSimulationsError && <FatalErrorModal />}
      {hasOpenInstallment && (
        <FeedbackPopUp variant="warning">
          <Box fullWidth padding="0 lg 0 0">
            <Typography gutterBottom variant="support">
              {t(`feedbackPopUp.openInstallment`)}
            </Typography>
            <Typography variant="action">
              {t(`feedbackPopUp.viewOpenInstallment`)}
            </Typography>
          </Box>
        </FeedbackPopUp>
      )}
      <ScrollableHeader
        navigationOnCLick={onGoBack}
        navigationText={t(`header.linkTitle`)}
        mainBackgroundColor="#ffffff"
        minimizedBackgroundColor="#f7f7f7"
        mainTextColor="#334444"
        minimizedTextColor="#334444"
        mainArrowColor="#3377bb"
        minimizedArrowColor="#3377bb"
      >
        <Typography variant="h5">{t(`header.description`)}</Typography>
      </ScrollableHeader>
      <Box padding="md sm sm sm" display="flex" flexGrow="1">
        <Form
          allowCustomLoanTerm={allowCustomLoanTerm}
          loanTermOptions={loanTermOptions}
          maxLoanAmount={maxLoanAmount}
          minLoanAmount={minLoanAmount}
          nextStepMessage={nextStepMessage}
          onSubmit={onSubmit}
          displaySimulationHelperMessage={displaySimulationHelperMessage}
          translation={translation}
        />
      </Box>
      <ScrollToTop />
    </Box>
  )
}

Calculator.propTypes = propTypes

const CalculatorWithRouter = withRouter(Calculator)
export {CalculatorWithRouter as Calculator}
