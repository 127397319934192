import {css} from '@creditas/stylitas'
import {utilities} from './../../utils/cssHelpers'

const RunningBarStyle = ({
  width,
  height,
  margin,
  marginBottom,
  marginTop,
}) => css`
  border-radius: 2px;
  height: ${height};
  width: ${width};
  background: linear-gradient(
    90deg,
    rgba(242, 242, 242, 1) 0%,
    rgba(227, 227, 227, 1) 50%,
    rgba(242, 242, 242, 1) 100%
  );
  animation: move${width} 1s linear infinite;
  ${margin && utilities.margin.all(margin)};
  ${
    marginBottom ? utilities.margin.bottom(marginBottom) : 'margin-bottom: 0px'
  };
  ${marginTop ? utilities.margin.top(marginTop) : 'margin-top: 0px'};

  @keyframes move${width} {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: ${width} ${width};
    }
  }
`

export {RunningBarStyle}
