import {createReducer} from 'redux-act'
import {actions} from './application.actions'

const STATE_KEY = 'application'
const initialState = {}

const applicationReducers = createReducer(
  {
    [actions.setApplicationInfo]: (state, applicationInfo) => {
      return {...state, ...applicationInfo}
    },
  },
  initialState,
)

const registerApplicationReducer = () => ({
  [STATE_KEY]: applicationReducers,
})

const selectorApplication = state => state[STATE_KEY]

export {selectorApplication, registerApplicationReducer}
