import React from 'react'
import {Typography} from '@creditas/typography'
import {IconAlertCircle} from '@creditas/icons'
import {Box} from '../../../components/Box'
import {useOngoingLoan} from './useOngoingLoan.hook'
import {formatMoney} from './../../../utils/formatMoney'

const OngoingLoan = () => {
  const {t, totalInstallmentAmount} = useOngoingLoan()

  return (
    <Box
      backgroundColor="#eeffff"
      margin="0 sm md sm"
      padding="sm"
      display="flex"
      flexGrow="1"
      radius="medium"
    >
      <Box fullWidth display="flex" alignItems="flex-start">
        <Box width="32px" height="32px" margin="xs xs sm -8px">
          <IconAlertCircle color="#4499ee" width="32px" height="32px" />
        </Box>
        <Box>
          <Typography variant="supportForm" color="#4499ee" gutterBottom>
            {t(`text.ongoingLoan`)}
          </Typography>
          <Typography variant="supportForm" color="#4499ee">
            {t(`text.ongoingLoanCont`, {
              presentInstallmentValue: formatMoney(totalInstallmentAmount),
              newInstallmentValue: formatMoney(totalInstallmentAmount),
            })}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export {OngoingLoan}
