import axios from 'axios'
import {api, CancelToken} from '../api'

let cancelLimit, cancelSimulation

const getCustumerLoanLimits = ({sessionToken, product}) => {
  if (cancelLimit) cancelLimit()

  return api
    .get(`/limits`, {
      params: {product},
      cancelToken: new CancelToken(function executor(c) {
        cancelLimit = c
      }),
      headers: {Authorization: `Bearer ${sessionToken}`},
    })
    .then(response => response.data)
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error
      }
    })
}

const simulateLoan = ({sessionToken, loanAmount, product}) => {
  if (cancelSimulation) cancelSimulation()

  return api
    .get(`/simulations`, {
      params: {requestedAmount: loanAmount, withIq: true, product},
      cancelToken: new CancelToken(function executor(c) {
        cancelSimulation = c
      }),
      headers: {Authorization: `Bearer ${sessionToken}`},
    })
    .then(response => response.data)
    .catch(error => {
      if (!axios.isCancel(error)) {
        throw error
      }
    })
}

export {simulateLoan, getCustumerLoanLimits}
