function formatMoney(amount, decimalCount = 2, decimal = ',', thousands = '.') {
  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount

  const negativeSign = amount < 0 ? '-' : ''

  const i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    10,
  ).toString()

  const j = i.length > 3 ? i.length % 3 : 0

  return `${negativeSign} R$ ${j ? i.substr(0, j) + thousands : ''}${i
    .substr(j)
    .replace(/(\d{3})(?=\d)/g, '$1')}${
    decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : ''
  }`
}

export {formatMoney}
