import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import {NewRelic} from '@creditas/new-relic-react-lib'
import {hotjar} from 'react-hotjar'
import {mock} from './mock.enable'

import {App} from './App'

import './i18n'

const {
  REACT_APP_ENV,
  REACT_APP_REVIEW_ID = '',
  REACT_APP_NEW_RELIC_KEY,
  REACT_APP_NEW_RELIC_ID,
  REACT_APP_HOT_JAR_ID,
  REACT_APP_HOT_SNIPPET_VERSION,
} = process.env

async function run() {
  await mock()

  hotjar.initialize(REACT_APP_HOT_JAR_ID, REACT_APP_HOT_SNIPPET_VERSION)

  Sentry.init({
    dsn:
      'https://23493e3c7afa4bc38fc65158689a4f8c@o274735.ingest.sentry.io/5191218',
    environment: `${REACT_APP_ENV}${REACT_APP_REVIEW_ID}`,
  })

  ReactDOM.render(<App />, document.getElementById('root'))
  ReactDOM.render(
    <NewRelic
      licenseKey={REACT_APP_NEW_RELIC_KEY}
      applicationID={REACT_APP_NEW_RELIC_ID}
      onlyConfig
    />,
    document.getElementById('new-relic'),
  )
}

run()
