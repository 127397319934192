import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Typography} from '@creditas-ui/typography'
import {Flex} from '@creditas-ui/layout'
import {Box} from './../Box'
import {ArrowIcon} from './ArrowIcon'

const propTypes = {
  navigationOnCLick: PropTypes.any,
  navigationText: PropTypes.string,
  children: PropTypes.any,
  mainBackgroundColor: PropTypes.string,
  minimizedBackgroundColor: PropTypes.string,
  mainTextColor: PropTypes.array,
  minimizedTextColor: PropTypes.array,
  mainArrowColor: PropTypes.string,
  minimizedArrowColor: PropTypes.string,
}

const ScrollableHeader = ({
  navigationOnCLick,
  navigationText,
  mainBackgroundColor,
  minimizedBackgroundColor,
  mainTextColor,
  minimizedTextColor,
  mainArrowColor,
  minimizedArrowColor,
  children,
}) => {
  const [arrowColor, setArrowColor] = useState(mainArrowColor)
  const [backgroundColor, setBackgroundColor] = useState(mainBackgroundColor)
  const [textColor, setTextColor] = useState(mainTextColor)

  const hexToRgb = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    return result
      ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16),
        ]
      : null
  }

  const colorFadeEffect = ({initialColor, finalColor}, callback) => {
    const headerElement = document.querySelector('.header-container')

    let rColor, gColor, bColor

    const [initialR, initialG, initialB] = hexToRgb(initialColor)
    const [finalR, finalG, finalB] = hexToRgb(finalColor)

    const rCoeficient = (initialR - finalR) / (headerElement.offsetHeight - 60)
    const gCoeficient = (initialG - finalG) / (headerElement.offsetHeight - 60)
    const bCoeficient = (initialB - finalB) / (headerElement.offsetHeight - 60)

    callback({rColor: initialR, gColor: initialG, bColor: initialB})

    window.addEventListener('scroll', () => {
      const windowOffset = Math.round(window.scrollY || window.pageYOffset)

      rColor =
        initialR < finalR
          ? Math.min(finalR, Math.round(initialR - windowOffset * rCoeficient))
          : Math.max(finalR, Math.round(initialR - windowOffset * rCoeficient))
      gColor =
        initialG < finalG
          ? Math.min(finalG, Math.round(initialG - windowOffset * gCoeficient))
          : Math.max(finalG, Math.round(initialG - windowOffset * gCoeficient))
      bColor =
        initialB < finalB
          ? Math.min(finalB, Math.round(initialB - windowOffset * bCoeficient))
          : Math.max(finalB, Math.round(initialB - windowOffset * bCoeficient))

      callback({rColor, gColor, bColor})
    })
  }

  useEffect(() => {
    colorFadeEffect(
      {initialColor: mainTextColor, finalColor: minimizedTextColor},
      ({rColor, gColor, bColor}) =>
        setTextColor(`rgb(${rColor}, ${gColor}, ${bColor})`),
    )
  }, [mainTextColor, minimizedTextColor]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    colorFadeEffect(
      {initialColor: mainBackgroundColor, finalColor: minimizedBackgroundColor},
      ({rColor, gColor, bColor}) =>
        setBackgroundColor(`rgb(${rColor}, ${gColor}, ${bColor})`),
    )
  }, [mainBackgroundColor, minimizedBackgroundColor]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    colorFadeEffect(
      {initialColor: mainArrowColor, finalColor: minimizedArrowColor},
      ({rColor, gColor, bColor}) =>
        setArrowColor(`rgb(${rColor}, ${gColor}, ${bColor})`),
    )
  }, [mainArrowColor, minimizedArrowColor]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className="header-container">
      <Flex
        bg={backgroundColor}
        style={{position: 'fixed', zIndex: '10'}}
        height="6rem"
        width="100%"
        onClick={navigationOnCLick}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Box style={{position: 'absolute', top: '1.6rem', left: '1rem'}}>
          <ArrowIcon color={arrowColor} className="header-text" />
        </Box>
        <Typography variant="bodyMdRegular" color={textColor}>
          {navigationText}
        </Typography>
      </Flex>
      <Box marginTop="77px">{children}</Box>
    </Box>
  )
}

ScrollableHeader.propTypes = propTypes

export {ScrollableHeader}
