import {createReducer} from 'redux-act'
import {actions} from './featureToggles.actions'
import {defaultFeatureFlags} from './../../constants'

const STATE_KEY = 'featureToggles'
const initialState = {featureToggles: defaultFeatureFlags}

const featureTogglesReducers = createReducer(
  {
    [actions.setFeatureToggles]: (state, {featureToggles}) => {
      return {...state, featureToggles}
    },
  },
  initialState,
)

const registerFeatureTogglesReducer = () => ({
  [STATE_KEY]: featureTogglesReducers,
})

const selectorFeatureToggles = state => state[STATE_KEY]

export {selectorFeatureToggles, registerFeatureTogglesReducer}
