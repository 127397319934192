/* eslint-disable no-negated-condition */
import {css} from '@creditas/stylitas'

const modalWrapper = ({fullscreen}) => css`
  padding: 16px;
  background-color: #ffffff;
  z-index: 1000;
  ${fullscreen
    ? `
      height: 100%;
      minHeight: 100vh;
      width : 100vw;
      `
    : ''}
  ${!fullscreen
    ? `
      margin : 0 20px;
      border-radius: 5px;
      `
    : ''}
  
  & div.modal-content {
    ${fullscreen
      ? `
        height: 100%;
        padding-bottom: 48px;
      `
      : ''}
  }
`

export {modalWrapper}
