import {useCallback} from 'react'
import {useMappedState} from 'redux-react-hook'
import {selectorLoanConditions} from './loanConditions.reducer'

const useLoanConditionsState = () => {
  const {
    isFetchingLimits,
    isFetchingLoanSimulations,
    limits,
    loanAmount,
    loanSimulations,
    loanTerm,
    maxLoanTerm,
    minLoanTerm,
    productType,
    selectedSimulation,
    fetchLimitError,
    fetchLoanSimulationsError,
  } = useMappedState(useCallback(selectorLoanConditions))

  return {
    isFetchingLimits,
    isFetchingLoanSimulations,
    limits,
    loanAmount,
    loanSimulations,
    loanTerm,
    maxLoanTerm,
    minLoanTerm,
    productType,
    selectedSimulation,
    fetchLimitError,
    fetchLoanSimulationsError,
  }
}

export {useLoanConditionsState}
