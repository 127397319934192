import {createAction} from 'redux-act'
import {SplitFactory as splitio} from '@splitsoftware/splitio'
import {config} from './../../config'
import {defaultFeatureFlags} from './../../constants'

const actions = {
  fetchFeatureToggles: createAction('set the fetchFeatureToggles'),
  setFeatureToggles: createAction('set the client'),
}

const fetchFeatureToggles = () => {
  return dispatch => {
    const factory = splitio(config.split)
    const client = factory.client()
    // eslint-disable-next-line prefer-const
    let featureToggles = {}

    client.on(client.Event.SDK_READY, () => {
      // eslint-disable-next-line no-unused-vars, guard-for-in
      for (const key in defaultFeatureFlags) {
        featureToggles[key] = client.getTreatment(key)
      }
      dispatch(actions.setFeatureToggles({featureToggles}))
    })
  }
}

const setFeatureToggles = ({featureToggles}) => {
  return dispatch => {
    dispatch(actions.setFeatureToggles({featureToggles}))
  }
}

export {actions, fetchFeatureToggles, setFeatureToggles}
