import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {Redirect, withRouter} from 'react-router'
import {setSessionTokenFromUrl} from './../../helpers/sessionToken'

const propTypes = {
  location: PropTypes.any,
}

const Router = ({location}) => {
  useEffect(() => {
    setSessionTokenFromUrl({location})
  }, [location])

  return <Redirect to="/auto-equity/calculadora" />
}

Router.propTypes = propTypes

const RouterWithRoute = withRouter(Router)
export {RouterWithRoute as Router}
