import React from 'react'
import {withRouter} from 'react-router'
import PropTypes from 'prop-types'
import CurrencyFormat from 'react-currency-format'
import {Typography} from '@creditas/typography'
import {Button} from '@creditas/button'
import {Proposal} from './Proposal'
import {BankInfo} from './BankInfo'
import {Faq} from './Faq'
import {Export} from './Export'
import {OngoingLoan} from './OngoingLoan'
import {Box} from './../Box'
import {ScrollToTop} from './../ScrollToTop'
import {ScrollableHeader} from './../ScrollableHeader'
import {useSimulationSummary} from './useSimulationSummary.hook'

const propTypes = {
  onSubmit: PropTypes.any,
  onGoBack: PropTypes.any,
  product: PropTypes.string,
}

const SimulationSummary = ({onSubmit, onGoBack, product}) => {
  const {
    t,
    isFetchingApplication,
    loanAmount,
    hasOngoingLoan,
  } = useSimulationSummary({product})

  return (
    <Box
      backgroundColor="#f7f7f7"
      height="100%"
      display="flex"
      flexDirection="column"
      style={{minHeight: '100vh'}}
    >
      <ScrollableHeader
        mainBackgroundColor="#11bb77"
        minimizedBackgroundColor="#ffffff"
        mainTextColor="#ffffff"
        minimizedTextColor="#334444"
        mainArrowColor="#ffffff"
        minimizedArrowColor="#3377bb"
        navigationOnCLick={onGoBack}
        navigationText={t(`header.linkTitle`)}
      >
        <Box marginBottom="md">
          <Typography variant="h5" color="#ffffff">
            {t(`header.description`)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="paragraph" color="#ffffff">
            <span style={{fontWeight: '600', fontSize: '12px'}}>
              {t(`text.valueToReceive`)}
            </span>
          </Typography>
        </Box>
        <Box margin="0 0 sm 2px">
          <Typography variant="h4" color="#ffffff">
            <CurrencyFormat
              value={loanAmount}
              displayType="text"
              prefix={t(`common:labels.currency`)}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </Box>
      </ScrollableHeader>

      <Proposal
        onEditSimulation={onGoBack}
        onSubmit={onSubmit}
        isLoading={isFetchingApplication}
      />
      {hasOngoingLoan && <OngoingLoan />}
      <Box margin="0 sm 0 sm">
        <Typography variant="h6">{t(`text.moreInfo`)}</Typography>
      </Box>
      <BankInfo isLoading={isFetchingApplication} />
      <Export isLoading={isFetchingApplication} />
      <Faq />

      <Box margin="sm">
        <Button fullWidth type="submit" onClick={onSubmit}>
          {t('links.continue')}
        </Button>
        <Box fullWidth marginTop="sm">
          <Typography variant="supportForm" style={{textAlign: 'center'}}>
            {t(`text.nextStep`)}
          </Typography>
        </Box>
      </Box>
      <ScrollToTop />
    </Box>
  )
}

SimulationSummary.propTypes = propTypes
const SimulationSummaryWithRouter = withRouter(SimulationSummary)

export {SimulationSummaryWithRouter as SimulationSummary}
