import React from 'react'
import {withRouter} from 'react-router'

function content() {
  return `Página de teste. Digite seu e-mail para recever o contato de um dos consultores ou consultoras da Creditas`
}

const ContactTestPage = () => {
  return <div>{content()}</div>
}

const ContactTestPageWithRouter = withRouter(ContactTestPage)
export {ContactTestPageWithRouter as ContactTestPage}
