const isProduction = process.env.NODE_ENV === 'production'

const dispatchGAEvent = payload => {
  if (isProduction) {
    // Safeguard against problems
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'autoRefi_topUpApp',
      category: 'top_up_simulation',
      ...payload,
    })
  }
}

export const dispatchUserAgreedToSimulationAction = ({
  amount,
  installments,
}) => {
  dispatchGAEvent({
    action: 'agree',
    value: amount,
    label: {
      installments,
    },
  })
}

export const dispatchPageViewEvent = () => {
  dispatchGAEvent({
    event: 'page_view',
  })
}
