import {styled, css} from '@creditas/stylitas'

const gridContainer = ({
  theme,
  keepWhenMobile,
  size,
  verticalGap,
  horizontalGap,
  columnEqHeight,
  marginBottom,
}) => css`
  width: 100%;
  margin-bottom: ${marginBottom || '24px'};
  ${columnEqHeight
    ? `grid-template-rows:  minmax(
    ${columnEqHeight.min || 'min-content'}, ${columnEqHeight.max ||
        'max-content'}
  );`
    : ''}

  ${keepWhenMobile
    ? `
    display: grid;
    ${
      size
        ? `grid-template-columns: repeat(${size}, 1fr);`
        : `grid-template-columns: repeat(12, 1fr);`
    }
    grid-gap: ${verticalGap ? verticalGap : '30px'} ${
        horizontalGap ? horizontalGap : '30px'
      };
    place-items: start start;

    & div {
      ${theme.mediaScreen.tablet} {
        margin-bottom: 0px;
      }
    }
  `
    : `
    ${theme.mediaScreen.desktop} {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 30px 30px;
      place-items: start start;
    }
    `}
`
const gridItem = ({
  theme,
  startCol,
  endCol,
  startRow,
  endRow,
  verticalCenter,
  marginBottom,
}) => css`
  grid-column-start: ${startCol};
  grid-column-end: ${endCol};
  ${startRow && `grid-row-start: ${startRow};`}
  ${endRow && `grid-row-end: span ${endRow};`}
  ${verticalCenter && 'align-self: center;'}
  width: 100%;
  ${startRow && 'height: 100%;'}

  ${theme.mediaScreen.tablet} {
    margin-bottom: ${marginBottom || '24px'};
  }
`

const GridItem = styled.div`
  ${gridItem}
`
const GridContainer = styled.div`
  ${gridContainer}
`

export {GridContainer, GridItem}
