import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import {TaxesDetails as BaseTaxesDetails} from './../../components/SimulationSummary/TaxesDetails'
import {useTaxesDetails} from './useTaxesDetails.hook'

const propTypes = {
  history: PropTypes.any,
}

const TaxesDetails = ({history}) => {
  const {onGoBack} = useTaxesDetails({history})

  return <BaseTaxesDetails onGoBack={onGoBack} />
}

TaxesDetails.propTypes = propTypes

const TaxesDetailsWithRouter = withRouter(TaxesDetails)
export {TaxesDetailsWithRouter as TaxesDetails}
