import React from 'react'
import {withRouter} from 'react-router'
import PropTypes from 'prop-types'
import {Typography} from '@creditas/typography'
import {IconChevronRight} from '@creditas/icons'
import {Button} from '@creditas/button'
import {routes} from './../../../constants'
import {LoadingPanelAnimation} from './../../../components/LoadingPanelAnimation'
import {Box} from './../../../components/Box'
import {useProposal} from './useProposal.hook'
import {HorizontalLine} from './../../../components/HorizontalLine'
import {formatMoney} from './../../../utils/formatMoney'
import pencil from './pencil.png'

const propTypes = {
  history: PropTypes.any,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.any,
  onEditSimulation: PropTypes.any,
}

const Proposal = ({history, isLoading, onSubmit, onEditSimulation}) => {
  const {
    amountToBePaid,
    firstInstallmentDueDate,
    installmentAmount,
    installmentsExpirationDay,
    t,
    term,
  } = useProposal()

  return (
    <Box
      backgroundColor="#ffffff"
      margin="-32px sm md sm"
      padding="sm"
      display="flex"
      flexGrow="1"
      radius="medium"
      shadow
    >
      {isLoading ? (
        <Box
          fullWidth
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingPanelAnimation size="lg" />
        </Box>
      ) : (
        <Box fullWidth>
          <Box marginBottom="8px">
            <Typography
              style={{lineHeight: '7px'}}
              variant="paragraph"
              color="#778888"
            >
              <span style={{fontWeight: '600', fontSize: '11px'}}>
                {t(`text.installments`)}
              </span>
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              {t(`text.installmentsDisplay`, {
                loanTerm: term,
                installmentValue: formatMoney(installmentAmount),
              })}
            </Typography>
            <Box onClick={onEditSimulation}>
              <img
                src={pencil}
                alt="Mudar Condições"
                style={{width: '30px', height: '30px'}}
              />
            </Box>
          </Box>
          <Typography variant="supportForm">
            <span>{t(`text.totalValue`)}</span>
            <span>{formatMoney(amountToBePaid)}</span>
          </Typography>
          <HorizontalLine marginTop="sm" marginBottom="sm" />
          <Typography variant="h6">
            <span style={{fontWeight: '600', fontSize: '16px'}}>
              {t(`text.firstInstallment`, {dueDate: firstInstallmentDueDate})}
            </span>
          </Typography>
          <Typography variant="supportForm" color="#556666">
            {t(`text.dueDate`, {dueDate: installmentsExpirationDay})}
          </Typography>
          <Box
            marginTop="sm"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => history.push(routes.naeTaxesDetails)}
          >
            <Typography variant="h6">{t(`text.proposalDetails`)}</Typography>
            <IconChevronRight color="#8b8c90" />
          </Box>

          <HorizontalLine marginTop="sm" marginBottom="sm" />
          <Button fullWidth type="submit" onClick={onSubmit}>
            {t('links.continue')}
          </Button>
          <Box marginTop="sm">
            <Typography style={{textAlign: 'center'}} variant="supportForm">
              {t(`text.nextStep`)}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}

Proposal.propTypes = propTypes
const ProposalWithRouter = withRouter(Proposal)

export {ProposalWithRouter as Proposal}
