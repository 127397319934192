import {createAction} from 'redux-act'

const actions = {
  setCustomerInfo: createAction('set the customer information'),
}

const setCustomerInfo = customerInfo => {
  return dispatch => {
    dispatch(actions.setCustomerInfo(customerInfo))
  }
}

export {setCustomerInfo, actions}
