import {useCallback} from 'react'
import {useMappedState} from 'redux-react-hook'
import {selectorCustomer} from './customer.reducer'

const useCustomerState = () => {
  const {sessionToken, hasOpenInstallment, pollingAuthToken} = useMappedState(
    useCallback(selectorCustomer),
  )

  return {
    hasOpenInstallment,
    sessionToken,
    pollingAuthToken,
  }
}

export {useCustomerState}
