import {createReducer} from 'redux-act'
import {actions} from './customer.actions'

const STATE_KEY = 'customer'
const initialState = {sessionToken: null}

const customerReducers = createReducer(
  {
    [actions.setCustomerInfo]: (state, customerInfo) => {
      return {...state, ...customerInfo}
    },
  },
  initialState,
)

const registerCustomerReducer = () => ({
  [STATE_KEY]: customerReducers,
})

const selectorCustomer = state => state[STATE_KEY]

export {selectorCustomer, registerCustomerReducer}
