import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import {Calculator as BaseCalculator} from './../../components/Calculator'
import {useCalculator} from './useCalculator.hook'

const propTypes = {
  history: PropTypes.function,
  location: PropTypes.any,
}

const Calculator = ({history, location}) => {
  const {
    allowCustomLoanTerm,
    loanTermOptions,
    maxLoanAmount,
    minLoanAmount,
    onGoBack,
    onSubmit,
    product,
    t,
  } = useCalculator({history, location})

  return (
    <BaseCalculator
      allowCustomLoanTerm={allowCustomLoanTerm}
      loanTermOptions={loanTermOptions}
      maxLoanAmount={maxLoanAmount}
      minLoanAmount={minLoanAmount}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
      product={product}
      translation={t}
    />
  )
}

Calculator.propTypes = propTypes

const CalculatorWithRouter = withRouter(Calculator)
export {CalculatorWithRouter as Calculator}
