import {useTranslation} from 'react-i18next'
import {useApplicationState} from './../../../store/application/useApplicationState.hook'

const useProposal = () => {
  const {t} = useTranslation(['summary', 'common'])
  const {
    amountToBePaid,
    firstInstallmentDueDate,
    installmentAmount,
    installmentsExpirationDay,
    term,
  } = useApplicationState()

  return {
    amountToBePaid,
    firstInstallmentDueDate,
    installmentAmount,
    installmentsExpirationDay,
    t,
    term,
  }
}

export {useProposal}
