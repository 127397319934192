import {css, utilities as utilitiesLib} from '@creditas/stylitas'

const sizesAliases = {
  xs: '8px',
  sm: '16px',
  md: '32px',
  lg: '48px',
  xlg: '64px',
}

const margin = {
  all: size => {
    const [t, r, b, l] = size.split(' ').map(i => sizesAliases[i] || i)

    return css`
      margin: ${t} ${r} ${b} ${l};
    `
  },
  top: size => css`
    margin-top: ${sizesAliases[size] || size};
  `,
  bottom: size => css`
    margin-bottom: ${sizesAliases[size] || size};
  `,
  left: size => css`
    margin-left: ${sizesAliases[size] || size};
  `,
  rigth: size => css`
    margin-right: ${sizesAliases[size] || size};
  `,
}

const padding = {
  all: size => {
    const [t, r, b, l] = size.split(' ').map(i => sizesAliases[i] || i)

    return css`
      padding: ${t} ${r} ${b} ${l};
    `
  },
  top: size => css`
    padding-top: ${sizesAliases[size] || size};
  `,
  bottom: size => css`
    padding-bottom: ${sizesAliases[size] || size};
  `,
  left: size => css`
    padding-left: ${sizesAliases[size] || size};
  `,
  rigth: size => css`
    padding-right: ${sizesAliases[size] || size};
  `,
}

const utilities = {
  ...utilitiesLib,
  margin,
  padding,
}

export {utilities}
