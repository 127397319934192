const {
  REACT_APP_API_TOP_UP_URL,
  REACT_APP_API_AUTHENTICATION_URL,
  REACT_APP_SPLITIO_API_KEY,
  REACT_APP_API_TOP_UP_VERSION,
} = process.env

const config = {
  apiGateway: {
    authUrl: REACT_APP_API_AUTHENTICATION_URL,
    apiUrl: REACT_APP_API_TOP_UP_URL,
    apiVersion: REACT_APP_API_TOP_UP_VERSION,
  },
  split: {
    core: {
      authorizationKey: REACT_APP_SPLITIO_API_KEY,
      key: 'customer',
    },
  },
}

export {config}
