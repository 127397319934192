import React from 'react'
import PropTypes from 'prop-types'
import {Typography} from '@creditas/typography'
import {IconChevronRight} from '@creditas/icons'
import {Box} from './../../../components/Box'
import {LoadingPanelAnimation} from './../../../components/LoadingPanelAnimation'
import {HorizontalLine} from './../../../components/HorizontalLine'
import {useBankInfo} from './useBankInfo.hook'
import {ChangeBankAccount} from './ChangeBankAccount'

const BankInfo = ({isLoading}) => {
  const {
    account,
    accountType,
    bank,
    branch,
    isVisible,
    setIsVisible,
    t,
  } = useBankInfo()

  return (
    <Box
      backgroundColor="#ffffff"
      margin="sm"
      padding="sm"
      display="flex"
      flexGrow="1"
      radius="medium"
      shadow
    >
      {isLoading ? (
        <Box
          fullWidth
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingPanelAnimation />
        </Box>
      ) : (
        <Box fullWidth>
          <ChangeBankAccount
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          />
          <Typography variant="form">{t(`text.bankAccount`)}</Typography>
          <Box marginTop="sm">
            <Typography variant="form">{bank}</Typography>
            <Box>
              <Box fullWidth>
                <Typography variant="support">
                  <span>{t(`text.branch`)}</span>
                  <span style={{marginLeft: '5px', fontWeight: '600'}}>
                    {branch}
                  </span>
                </Typography>
                <Typography variant="support">
                  <span>{t(`text.account`)}</span>
                  <span style={{marginLeft: '5px', fontWeight: '600'}}>
                    {account}
                  </span>
                </Typography>
                <Typography variant="support">{accountType}</Typography>
              </Box>
            </Box>
            <HorizontalLine marginTop="sm" marginBottom="xs" />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              onClick={() => setIsVisible(true)}
              marginTop="10px"
            >
              <Typography
                variant="action"
                style={{textDecoration: 'underline'}}
              >
                {t(`links.change`)}
              </Typography>
              <IconChevronRight color="#3377BB" width="32px" height="32px" />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

BankInfo.propTypes = {
  isLoading: PropTypes.bool,
}

export {BankInfo}
