import {useCallback} from 'react'
import {useDispatch} from 'redux-react-hook'
import * as application from './application.actions'

const useApplicationActions = () => {
  const dispatch = useDispatch()
  const setApplicationInfo = useCallback(
    applicationInfo =>
      application.setApplicationInfo(applicationInfo)(dispatch),
    [dispatch],
  )

  return {setApplicationInfo}
}

export {useApplicationActions}
