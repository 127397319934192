import React from 'react'
import PropTypes from 'prop-types'
import {Typography} from '@creditas-ui/typography'
import {ButtonPrimaryTextOnly} from '@creditas-ui/button'
import {Grid, Flex} from '@creditas-ui/layout'
import {closeWebviewMessage} from '../../../helpers/webviewMessages'
import {Modal} from '../../../components/Modal'
import {Box} from '../../../components/Box'
import SuccessCheckIcon from './sucess_check_icon.png'
import {useSuccessMessage} from './useSuccessMessage.hook'

const propTypes = {
  isVisible: PropTypes.bool,
}

const SuccessMessage = ({isVisible}) => {
  const {t} = useSuccessMessage()

  return (
    <Modal fullscreen isVisible={isVisible} closeable={false}>
      <Grid gridTemplateRows="3fr 1fr" style={{padding: '1rem 1rem 0 1rem'}}>
        <Flex
          flexDirection="column"
          justifyContent="start"
          style={{marginTop: '3rem'}}
        >
          <img src={SuccessCheckIcon} width="100px" alt="Ícone de sucesso" />
        </Flex>

        <Flex flexDirection="column">
          <Box marginBottom="md">
            <Typography variant="headingMdLight">
              {t(`text.experiment.requestSent`)}
            </Typography>
            <Typography variant="bodyMdRegular" color="#6F7974">
              {t(`text.experiment.requestSentHelper`)}
            </Typography>
          </Box>
          <ButtonPrimaryTextOnly
            size="large"
            width="fixed"
            onClick={closeWebviewMessage}
          >
            {t(`links.experiment.understood`)}
          </ButtonPrimaryTextOnly>
        </Flex>
      </Grid>
    </Modal>
  )
}

SuccessMessage.propTypes = propTypes

export {SuccessMessage}
