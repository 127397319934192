import React from 'react'
import CurrencyFormat from 'react-currency-format'
import {InputBoxed} from '@creditas-ui/input'

const CurrencyTextField = props => {
  return (
    <CurrencyFormat
      {...props}
      customInput={InputBoxed}
      prefix="R$ "
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={0}
      fixedDecimalScale={true}
    />
  )
}

export {CurrencyTextField}
