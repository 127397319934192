import {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'
import {useSessionToken} from './../../hooks/useSessionToken.hook'
import {fetchApplication} from './../../services/api/application/fetch'
import {useApplicationActions} from './../../store/application/useApplicationActions.hook'
import {useApplicationState} from './../../store/application/useApplicationState.hook'
import {useLoanConditionsActions} from './../../store/loanConditions/useLoanConditionsAction.hook'

const useSimulationSummary = () => {
  const {t} = useTranslation(['summary', 'common'])
  const params = useParams()

  const {sessionToken} = useSessionToken()
  const {
    platformId,
    ongoingLoans,
    requestedAmount,
    term,
  } = useApplicationState()
  const {setApplicationInfo} = useApplicationActions()
  const {setLoanAmount, setLoanTerm} = useLoanConditionsActions()

  const [hasOngoingLoan, setHasOngoingLoan] = useState(false)
  const [isFetchingApplication, setIsFetchingApplication] = useState(true)

  useEffect(() => {
    setHasOngoingLoan(ongoingLoans && ongoingLoans.loans.length > 0)
  }, [ongoingLoans])

  useEffect(() => {
    setIsFetchingApplication(true)

    if (platformId === params.applicationId) {
      setIsFetchingApplication(false)
    } else {
      fetchApplication(setApplicationInfo)({
        sessionToken,
        applicationId: params.applicationId,
      }).then(application => {
        setLoanAmount({loanAmount: application.requestedAmount})
        setLoanTerm({loanTerm: application.term})

        setIsFetchingApplication(false)
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    hasOngoingLoan,
    isFetchingApplication,
    loanAmount: requestedAmount,
    loanTerm: term,
    t,
  }
}

export {useSimulationSummary}
